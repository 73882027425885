<template>
  <div class="utc-widget">
    <div class="utc-widget--time">
      <span>{{ time }}</span>
    </div>
    <div class="utc-widget--label">UTC</div>
  </div>
</template>

<script>
import {getUTCTime} from '@/utils/timeUtils';

export default {
  data() {
    return {
      time: getUTCTime('HH:mm:ss')
    }
  },
  mounted() {
    setInterval(this.getNow, 1000)
  },
  methods: {
    getNow() {
      this.time = getUTCTime('HH:mm:ss');
    }
  },
}
</script>

<style lang="scss" scoped>
.utc-widget {
  display: flex;
  min-width: 100px;
  align-items: center;
  justify-content: space-between;
    margin-top: 0.6rem;
    margin-left: 3px;
}

.utc-widget--time {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.1rem;
  width: 80px;
}

.utc-widget--label {
    margin-left: 5px;
    margin-right: 1rem;
}
</style>